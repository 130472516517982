<template>
   <f7-page class="productview-page alt-productview-page">
    <template #fixed>

     
      <Suspense>
        <ProductToolbarComponentV2 />
      </Suspense>
    </template>

    <section class="productview-container">
       <Suspense>
        <ProductNavigationComponentV2 />
      </Suspense>
      <div>
        <div class="prod-slider-container">
          <f7-swiper class="prod-swiper" >
            <f7-swiper-slide >
              <div>
                <img style="width:100%" :src="require('@/assets/images/demo/wines/1.png')"  class="product-image" loading="lazy" />
              </div>
            </f7-swiper-slide>
          </f7-swiper>
        </div>
        <div class="prod-details-content">
          <div class="vid-btn-com">
            <f7-button class="vid-btn" fill large raised><font-awesome-icon style="padding-right:5px" :icon="['far', 'comment']" fixed-width /> <p>Video Details Comment</p> </f7-button>
          </div>
        <div class="bundle-container" >
          <div class="bundle-product-container">
            <div class="image-container" >
              <img  :src="require('@/assets/images/demo/wines/3.png')"  class="product-image" loading="lazy" />
              <img  :src="require('@/assets/images/demo/wines/3.png')"  class="product-image" loading="lazy" />
            </div>
          </div>
        </div>
          <h1>PRODUCT NAME</h1>
            <div class="price-details-bundle">
              <div style="color: #1A64CA;">
                <h3>¥2,000.00</h3>
              </div>
                <h4 style="color: red; padding-left: 10px;">30% off</h4>
            </div>
              <div class="dicount-details" style="display: flex; justify-content: space-between; font-size: 12px;">
                <p >Discount Offer</p>
                <p class="disc-coup">Get Coupon ></p>
              </div>
        
        
        <div>
    
          <div class="prod-details-field" >
            <label>{{ $t.getTranslation("LBL_MERCHANT") }}</label>
            <span>PJFWINE</span>
          </div>

          <div  class="prod-details-field">
            <label>{{ $t.getTranslation("LBL_BRAND") }}</label>
            <span>PJFWINE</span>
          </div>

          <div  class="prod-details-field">
            <label>{{ $t.getTranslation("LBL_MATERIALS") }}</label>
            <span>PJFWINE</span>
          </div>

          <div  class="prod-details-field">
            <label>{{ $t.getTranslation("LBL_VINTAGE") }}</label>
            <span>PJFWINE</span>
          </div>

          <div class="prod-details-field">
            <label>{{ $t.getTranslation("LBL_TYPE") }}</label>
            <span>PJFWINE</span>
          </div>

          <div  class="prod-details-field">
            <label>{{ $t.getTranslation("LBL_SIZE") }}</label>
            <span>PJFWINE</span>
          </div>

          <div class="prod-details-field">
            <label>{{ $t.getTranslation("LBL_COUNTRY_OF_ORIGIN") }}</label>
            <span>France</span>
            <span class="flag-icon"><country-flag country="fr" size="normal" style="margin-left:2px"/></span>
            <!-- <span class="flag-icon"><country-flag :country="Product.CountryCode" size="normal" /></span> -->
          </div>

          <div  class="prod-details-field">
            <label>{{ $t.getTranslation("LBL_REGION_1") }}</label>
            <span>PJFWINE</span>
          </div>

          <div  class="prod-details-field">
            <label>{{ $t.getTranslation("LBL_REGION_2") }}</label>
            <span>PJFWINE</span>
          </div>

          <div class="prod-details-field">
            <label>{{ $t.getTranslation("LBL_WINERY") }}</label>
            <span>PJFWINE</span>
          </div>
          
        </div>
        <f7-list >
         
          <f7-list-item :title="$t.getTranslation('LBL_TASTING_NOTES')" >
            <template #media>
              <font-awesome-icon class="active" :icon="['fas', 'sticky-note']" fixed-width />
            </template>
          </f7-list-item>
        </f7-list>
        <div class="prod-details-field-notice" >
            <label>品酒坊购买须知</label>
            <span
              >您好! 为帮助您更好地选购跨境商品，请您在购买前务必认真、详细阅读并完全理解本须知的全部内容， 并对自身风险承担做出客观判断。请您在同意本须知的内容后再下单购买：
              <ol class="notice-list">
                <li>据海关相关规定，您选购的跨境商品仅限个人自用，不得进行再次销售。</li>
                <li>
                  为顺利安排您的订单发运，根据中国跨境电商零售进口相关规定，订单购买人、支付人与收件人必须一致，用户（支付人）需要提供正确的中国身份证号及与身份证一致的姓名（特请注意身份证尾号为“X”的客户，请您填写大写字母“X”
                  ，否则订单无法清关）。
                </li>
                <li>单笔订单交易总金额上限为人民币5000元，选购超过5000元请联系客服。</li>
                <li>请注意中国海关的购买限额记录，跨境电商产品的个人年度购买限额为26000人民币，如超过中国海关记录，货品将不获通关。</li>
                <li>跨境商品均从境外发货，符合原产地有关质量、安全、卫生、环保、标示等标准或技术规范要求，可能与我国标准有所不同，由此可能产生的危害、损失或者其他风险，将由客人自行承担。</li>
                <li>于品酒坊上购买的跨境商品等同于境外购买，商品本身无中文背标，您可通过网站查看商品中文电子标签，如果海外产品包装更换，您收到的商品可能与商品图片存在差异，请以收到的实物为准。</li>
                <li>跨境商品购物属境外消费，不能开具增值发票。</li>
              </ol>
            </span>
          </div>

      </div>
      </div>

      <Suspense v-if="Product && userToken">
        <ProductRatingComponent v-if="Product && userToken" :product="Product" />
      </Suspense>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, ref, onMounted, inject, computed, defineAsyncComponent } from "vue";
import { helpers } from "@/utils/helpers.js";
import { useStore } from "@/store";
import "@appnest/masonry-layout";

export default defineComponent({
  name: "ProductViewPageV2",
  components: {
    ProductNavigationComponentV2: defineAsyncComponent(() => import(/* webpackChunkName: "product-nav-2" */ /* webpackMode: "lazy" */ "@/components/navigations/ProductNavigationComponentV2.vue")),
    ProductToolbarComponentV2: defineAsyncComponent(() => import(/* webpackChunkName: "product-tool-bar-2" */ /* webpackMode: "lazy" */ "@/components/navigations/ProductToolbarComponentV2.vue")),
    ProductRatingComponent: defineAsyncComponent(() => import(/* webpackChunkName: "product-rating" */ /* webpackMode: "lazy" */ "@/components/ProductRatingComponent.vue")),
    CountryFlag: defineAsyncComponent(() => import(/* webpackChunkName: "country-flag" */ /* webpackMode: "lazy" */ "vue-country-flag-next")),
    },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const store = useStore();
    const userToken = computed(() => store.getters["user/getData"]?.Token);
    const navType = ref("back");

    

    return {
      store,
      userToken,
      navType,
      helpers,
    };
  },
});
</script>

<style scoped>
.alt-productview-page {
  padding-bottom: 100px;
}
.prod-image-disp {
    width: 100px;
    /* height: 100px; */
    padding-top: 0px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin-right: 10px;
}
.prod-swiper .prod-image-disp {
    width: 100%;
    padding-top: 0px;
    cursor: pointer;
    border: 0px solid #f7f7f7;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 5);
}
.prod-slider-container {
  position: relative;
}
.vid-btn-com {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.vid-btn{
  width: 80%;
  border-radius: 25px;
  font-size: 14px;
  background-color: #1a64ca;
  text-transform: none;
}
.prod-details-content {
    display: flex;
    flex-direction: column;
    align-content: center;
    padding: 0 30px;
}
.price-details-bundle {
    display: flex;
}
.disc-coup {
    background: #ebf4ec;
    color: #399443;
    padding: 5px 10px;
    border-radius: 25px;
}
.prod-swiper {
  overflow: hidden;
  margin-bottom: 30px;
  border: 1px solid #f7f7f7;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1);
}
.prod-swiper .prod-image-disp {
  width: 100%;
  padding-top: 60%;
  cursor: pointer;
}
.bundle-container .bundle-product-container > .image-container {
    width: 100%;
    height: 90px;
    margin: 10px 10px 10px 0px;
    cursor: pointer;
    padding: 5px 5px 5px 0px;
    display: flex;
    flex-direction: row;
}
.bundle-container .bundle-product-container > .image-container img {
    margin-right: 10px;
    width: 80px;
    height: 80px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 5px;
}
.productview-container {
  width: calc(100% - 40px);
  margin: 20px auto 40px;
  border: 0px solid #f7f7f7;
  box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0.1);
}
.prod-details-field {
  padding: 10px;
  padding-left: 0px;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.prod-details-field-notice {
  padding: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.prod-details-field:last-child {
  border: none;
}

.prod-details-field label {
  font-weight: bold;
  margin-right: 20px;
}
.prod-details-field-notice label {
  font-weight: bold;
  padding-bottom: 20px;
}
.notice-list {
    padding-left: 15px;
}
</style>
